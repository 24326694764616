export const opts = {
	host: 'cn-beijing.log.aliyuncs.com', // 所在地域的服务入口。例如cn-hangzhou.log.aliyuncs.com
	project: 'ybl-saas-new', // Project名称。
	logstore: 'insuretrade-behavior-data', // Logstore名称。
	time: 10, // 发送日志的时间间隔，默认是10秒。
	count: 10, // 发送日志的数量大小，默认是10条。
	topic: 'ybl-instrade-hmb', // 自定义日志主题。
	source: process.env.NODE_ENV === 'production' ? 'product' : 'develop',
	tags: {},
}
export const agreementFileCode = [
  {
  	text: '投保须知',
  	url: 'https://f2.youbaolian.top/ysf/2025/%E6%8A%95%E4%BF%9D%E9%A1%BB%E7%9F%A5.pdf',
  	value: 1,
  	page: 9
  }, {
		text: '重要提示',
		url: 'https://f2.youbaolian.top/ysf/2025/%E9%87%8D%E8%A6%81%E6%8F%90%E7%A4%BA.pdf',
		value: 2,
		page: 8
	}, {
		text: '保障计划表',
		url: 'https://f2.youbaolian.top/ysf/2023/%E4%BF%9D%E9%9A%9C%E8%AE%A1%E5%88%92%E8%A1%A8.pdf?12',
		value: 13,
		page: 5
	}, {
		text: '特药清单',
		url: 'https://f2.youbaolian.top/ysf/2023/%E7%89%B9%E8%8D%AF%E6%B8%85%E5%8D%95.pdf',
		value: 14,
		page: 25
	}, {
		text: '理赔须知',
		url: 'https://f2.youbaolian.top/ysf/2025/%E7%90%86%E8%B5%94%E9%A1%BB%E7%9F%A5.pdf',
		value: 15,
		page: 23
	}, {
		text: '隐私政策',
		url: 'https://f2.youbaolian.top/ysf/2025/%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.pdf',
		value: 16,
		page: 13
	}, {
		text: '用户协议',
		url: 'https://f2.youbaolian.top/ysf/2025/%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE.pdf',
		value: 17,
		page: 5
	}, {
		text: '医保个人信息查询、个人账户专户支付及短信息发送授权书',
		url: 'https://f2.youbaolian.top/ysf/2025/%E5%8C%BB%E4%BF%9D%E4%B8%AA%E4%BA%BA%E4%BF%A1%E6%81%AF%E6%9F%A5%E8%AF%A2%E3%80%81%E4%B8%AA%E4%BA%BA%E8%B4%A6%E6%88%B7%E4%B8%93%E6%88%B7%E6%94%AF%E4%BB%98%E3%80%81%E5%95%86%E4%BF%9D%E4%B8%AA%E4%BA%BA%E4%BF%A1%E6%81%AF%E4%BD%BF%E7%94%A8%E5%8F%8A%E7%9F%AD%E4%BF%A1%E6%81%AF%E5%8F%91%E9%80%81%E6%8E%88%E6%9D%83%E4%B9%A6.pdf',
		value: 18,
		page: 3
	}, {
		text: '中国人寿财产保险股份有限公司北京市医保补充医疗保险（2024版互联网专属）条款',
		url: 'https://f2.youbaolian.top/ysf/2025/%E4%B8%AD%E5%9B%BD%E4%BA%BA%E5%AF%BF%E8%B4%A2%E4%BA%A7%E4%BF%9D%E9%99%A9%E8%82%A1%E4%BB%BD%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8%E5%8C%97%E4%BA%AC%E5%B8%82%E5%8C%BB%E4%BF%9D%E8%A1%A5%E5%85%85%E5%8C%BB%E7%96%97%E4%BF%9D%E9%99%A9%EF%BC%882024%E7%89%88%E4%BA%92%E8%81%94%E7%BD%91%E4%B8%93%E5%B1%9E%EF%BC%89%E6%9D%A1%E6%AC%BE.pdf',
		value: 19,
		page: 22
	}, {
		text: '增值服务告知书',
		url: 'https://f2.youbaolian.top/ysf/pingan/%E5%A2%9E%E5%80%BC%E6%9C%8D%E5%8A%A1%E5%91%8A%E7%9F%A5%E4%B9%A6.pdf',
		value: 103,
		page: 10
	}, {
		text: '参保资格承诺书',
		url: 'https://f2.youbaolian.top/ysf/2025/%E5%8F%82%E4%BF%9D%E8%B5%84%E6%A0%BC%E6%89%BF%E8%AF%BA%E4%B9%A6.pdf',
		value: 120,
		page: 4
	}, {
		text: '客户告知书',
		url: 'https://f2.youbaolian.top/ysf/2025/%E5%AE%A2%E6%88%B7%E5%91%8A%E7%9F%A5%E4%B9%A6.pdf',
		value: 121,
		page: 1
	}, {
		text: '既往症特别约定',
		url: 'https://f2.youbaolian.top/ysf/2025/%E6%97%A2%E5%BE%80%E7%97%87%E7%89%B9%E5%88%AB%E7%BA%A6%E5%AE%9A.pdf',
		value: 123,
		page: 4
	}
]
export const agreementFileCodePA = [
	{
		text: '投保须知',
		url: 'https://f2.youbaolian.top/ysf/pingan/%E6%8A%95%E4%BF%9D%E9%A1%BB%E7%9F%A5.pdf',
		value: 1,
		page: 11
	}, {
		text: '特别约定',
		url: 'https://f2.youbaolian.top/ysf/pingan/%E7%89%B9%E5%88%AB%E7%BA%A6%E5%AE%9A.pdf',
		value: 2,
		page: 2
	}, {
		text: '北京市医保补充医疗保险（2022版）（互联网专属）条款',
		url: 'https://f2.youbaolian.top/ysf/pingan/%E5%8C%97%E4%BA%AC%E5%B8%82%E5%8C%BB%E4%BF%9D%E8%A1%A5%E5%85%85%E5%8C%BB%E7%96%97%E4%BF%9D%E9%99%A9%EF%BC%882022%E7%89%88%EF%BC%89%EF%BC%88%E4%BA%92%E8%81%94%E7%BD%91%E4%B8%93%E5%B1%9E%EF%BC%89%E6%9D%A1%E6%AC%BE.pdf',
		value: 3,
		page: 10
	}, {
		text: '特药服务告知书',
		url: 'https://f2.youbaolian.top/ysf/pingan/%E7%89%B9%E8%8D%AF%E6%9C%8D%E5%8A%A1%E5%91%8A%E7%9F%A5%E4%B9%A6.pdf',
		value: 4,
		page: 3
	}, {
		text: '责任免除',
		url: 'https://f2.youbaolian.top/ysf/pingan/%E8%B4%A3%E4%BB%BB%E5%85%8D%E9%99%A4.pdf',
		value: 5,
		page: 5
	}, {
		text: '个人信息保护政策',
		url: 'https://f2.youbaolian.top/ysf/pingan/%E4%B8%AA%E4%BA%BA%E4%BF%A1%E6%81%AF%E4%BF%9D%E6%8A%A4%E6%94%BF%E7%AD%96.pdf',
		value: 6,
		page: 17
	}, {
		text: '北京普惠健康保保障计划表',
		url: 'https://f2.youbaolian.top/ysf/pingan/%E5%8C%97%E4%BA%AC%E6%99%AE%E6%83%A0%E5%81%A5%E5%BA%B7%E4%BF%9D%E4%BF%9D%E9%9A%9C%E8%AE%A1%E5%88%92%E8%A1%A8.pdf',
		value: 100,
		page: 2
	}, {
		text: '既往症特别约定',
		url: 'https://f2.youbaolian.top/ysf/2023/%E6%97%A2%E5%BE%80%E7%97%87%E7%89%B9%E5%88%AB%E7%BA%A6%E5%AE%9A.pdf',
		value: 101,
		page: 2
	}, {
		text: '特定药品目录',
		url: 'https://f2.youbaolian.top/ysf/pingan/%E7%89%B9%E5%AE%9A%E8%8D%AF%E5%93%81%E7%9B%AE%E5%BD%95.pdf',
		value: 102,
		page: 18
	}, {
		text: '增值服务告知书',
		url: 'https://f2.youbaolian.top/ysf/pingan/%E5%A2%9E%E5%80%BC%E6%9C%8D%E5%8A%A1%E5%91%8A%E7%9F%A5%E4%B9%A6.pdf',
		value: 103,
		page: 10
	}
]
export const agreementFileCodeRS = [
	{
		text: '投保须知',
		url: 'https://f2.youbaolian.top/ysf/2023/%E6%8A%95%E4%BF%9D%E9%A1%BB%E7%9F%A5%E4%B9%A6.pdf',
		value: 1,
		page: 5
	}, {
		text: '重要提示',
		url: 'https://f2.youbaolian.top/ysf/2023/%E9%87%8D%E8%A6%81%E6%8F%90%E7%A4%BA.pdf',
		value: 2,
		page: 7
	}, {
		text: '保险条款',
		url: 'https://f2.youbaolian.top/ysf/2023/%E5%8C%97%E4%BA%AC%E5%B8%82%E5%8C%BB%E4%BF%9D%E8%A1%A5%E5%85%85%E5%8C%BB%E7%96%97%E4%BF%9D%E9%99%A9%EF%BC%882022%E7%89%88%EF%BC%89%E6%9D%A1%E6%AC%BE.pdf',
		value: 11,
		page: 21
	}, {
		text: '保险经纪委托协议',
		url: 'https://f2.youbaolian.top/ysf/2023/%E4%BF%9D%E9%99%A9%E7%BB%8F%E7%BA%AA%E5%A7%94%E6%89%98%E5%8D%8F%E8%AE%AE.pdf',
		value: 12,
		page: 5
	}, {
		text: '保障计划表',
		url: 'https://f2.youbaolian.top/ysf/2023/%E4%BF%9D%E9%9A%9C%E8%AE%A1%E5%88%92%E8%A1%A8.pdf?12',
		value: 13,
		page: 5
	}, {
		text: '特药清单',
		url: 'https://f2.youbaolian.top/ysf/2023/%E7%89%B9%E8%8D%AF%E6%B8%85%E5%8D%95.pdf',
		value: 14,
		page: 25
	}, {
		text: '健康管理服务',
		url: 'https://f2.youbaolian.top/ysf/2023/%E5%A2%9E%E5%80%BC%E6%9C%8D%E5%8A%A1.pdf',
		value: 15,
		page: 11
	}, {
		text: '理赔须知',
		url: 'https://f2.youbaolian.top/ysf/2023/%E7%90%86%E8%B5%94%E9%A1%BB%E7%9F%A5.pdf',
		value: 16,
		page: 7
	}, {
		text: '医保个人信息查询、个人账户专户支付及短信息发送授权书',
		url: 'https://f2.youbaolian.top/ysf/2023/%E5%8C%BB%E4%BF%9D%E4%B8%AA%E4%BA%BA%E4%BF%A1%E6%81%AF%E4%B8%AA%E8%B4%A6%E6%94%AF%E4%BB%98%E5%8F%8A%E7%9F%AD%E4%BF%A1%E6%81%AF%E5%8F%91%E9%80%81%E6%8E%88%E6%9D%83%E4%B9%A6.pdf',
		value: 17,
		page: 3
	}, {
		text: '增值服务告知书',
		url: 'https://f2.youbaolian.top/ysf/pingan/%E5%A2%9E%E5%80%BC%E6%9C%8D%E5%8A%A1%E5%91%8A%E7%9F%A5%E4%B9%A6.pdf',
		value: 103,
		page: 10
	}, {
		text: '隐私政策',
		url: 'https://f2.youbaolian.top/ysf/2023/%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.pdf',
		value: 118,
		page: 12
	}, {
		text: '用户协议',
		url: 'https://f2.youbaolian.top/ysf/2023/%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE.pdf',
		value: 119,
		page: 5
	}, {
		text: '参保资格承诺书',
		url: 'https://f2.youbaolian.top/ysf/2023/%E5%8F%82%E4%BF%9D%E8%B5%84%E6%A0%BC%E6%89%BF%E8%AF%BA%E4%B9%A6.pdf',
		value: 120,
		page: 3
	}, {
		text: '客户告知书',
		url: 'https://f2.youbaolian.top/ysf/2023/%E5%AE%A2%E6%88%B7%E5%91%8A%E7%9F%A5%E4%B9%A6.pdf',
		value: 121,
		page: 2
	}, {
		text: '消费者权益保障提示',
		url: 'https://f2.youbaolian.top/ysf/2023/%E6%B6%88%E8%B4%B9%E8%80%85%E6%9D%83%E7%9B%8A%E4%BF%9D%E9%9A%9C%E6%8F%90%E7%A4%BA.pdf',
		value: 122,
		page: 1
	}, {
		text: '既往症特别约定',
		url: 'https://f2.youbaolian.top/ysf/2023/%E6%97%A2%E5%BE%80%E7%97%87%E7%89%B9%E5%88%AB%E7%BA%A6%E5%AE%9A.pdf',
		value: 123,
		page: 2
	}
]
export const agreementFileCodeRB = [
	{
		text: '投保须知',
		url: 'https://f2.youbaolian.top/ysf/picc/%E6%8A%95%E4%BF%9D%E9%A1%BB%E7%9F%A5.pdf',
		value: 1,
		page: 5
	}, {
		text: '特别约定',
		url: 'https://f2.youbaolian.top/ysf/picc/%E7%89%B9%E5%88%AB%E7%BA%A6%E5%AE%9A.pdf',
		value: 2,
		page: 4
	}, {
		text: '责任免除',
		url: 'https://f2.youbaolian.top/ysf/picc/%E8%B4%A3%E4%BB%BB%E5%85%8D%E9%99%A4.pdf',
		value: 3,
		page: 3
	}, {
		text: '投保声明',
		url: 'https://f2.youbaolian.top/ysf/picc/%E6%8A%95%E4%BF%9D%E5%A3%B0%E6%98%8E.pdf',
		value: 4,
		page: 1
	}, {
		text: '北京市医保补充医疗保险条款',
		url: 'https://f2.youbaolian.top/ysf/picc/%E5%8C%97%E4%BA%AC%E5%B8%82%E5%8C%BB%E4%BF%9D%E8%A1%A5%E5%85%85%E5%8C%BB%E7%96%97%E4%BF%9D%E9%99%A9%E6%9D%A1%E6%AC%BE.pdf',
		value: 5,
		page: 10
	}, {
		text: '参保资格承诺书',
		url: 'https://f2.youbaolian.top/ysf/picc/%E5%8F%82%E4%BF%9D%E8%B5%84%E6%A0%BC%E6%89%BF%E8%AF%BA%E4%B9%A6.pdf',
		value: 6,
		page: 3
	}, {
		text: '北京普惠健康保理赔须知',
		url: 'https://f2.youbaolian.top/ysf/picc/%E5%8C%97%E4%BA%AC%E6%99%AE%E6%83%A0%E5%81%A5%E5%BA%B7%E4%BF%9D%E7%90%86%E8%B5%94%E9%A1%BB%E7%9F%A5.pdf',
		value: 7,
		page: 5
	}, {
		text: '医保个人信息查询、个人账户专户支付及短信息发送授权书',
		url: 'https://f2.youbaolian.top/ysf/picc/%E5%8C%BB%E4%BF%9D%E4%B8%AA%E4%BA%BA%E4%BF%A1%E6%81%AF%E6%9F%A5%E8%AF%A2%E3%80%81%E4%B8%AA%E4%BA%BA%E8%B4%A6%E6%88%B7%E4%B8%93%E6%88%B7%E6%94%AF%E4%BB%98%E5%8F%8A%E7%9F%AD%E4%BF%A1%E6%81%AF%E5%8F%91%E9%80%81%E6%8E%88%E6%9D%83%E4%B9%A6.pdf',
		value: 8,
		page: 2
	}, {
		text: '北京普惠健康保产品保障方案',
		url: 'https://f2.youbaolian.top/ysf/picc/%E5%8C%97%E4%BA%AC%E6%99%AE%E6%83%A0%E5%81%A5%E5%BA%B7%E4%BF%9D%E4%BA%A7%E5%93%81%E4%BF%9D%E9%9A%9C%E6%96%B9%E6%A1%88.pdf',
		value: 9,
		page: 3
	}, {
		text: '北京普惠健康保特药服务告知书-2022版',
		url: 'https://f2.youbaolian.top/ysf/picc/%E5%8C%97%E4%BA%AC%E6%99%AE%E6%83%A0%E5%81%A5%E5%BA%B7%E4%BF%9D%E7%89%B9%E8%8D%AF%E6%9C%8D%E5%8A%A1%E5%91%8A%E7%9F%A5%E4%B9%A6-2022%E7%89%88.pdf',
		value: 10,
		page: 13
	}, {
		text: '健康管理服务目录',
		url: 'https://f2.youbaolian.top/ysf/picc/%E5%81%A5%E5%BA%B7%E7%AE%A1%E7%90%86%E6%9C%8D%E5%8A%A1%E7%9B%AE%E5%BD%95.pdf',
		value: 11,
		page: 7
	}, {
		text: '个人信息保护政策',
		url: 'https://f2.youbaolian.top/ysf/2023/%E4%B8%AA%E4%BA%BA%E4%BF%A1%E6%81%AF%E4%BF%9D%E6%8A%A4%E6%94%BF%E7%AD%96.pdf',
		value: 12,
		page: 17
	}, {
		text: '北京普惠健康保保障计划表',
		url: 'https://f2.youbaolian.top/ysf/picc/%E5%8C%97%E4%BA%AC%E6%99%AE%E6%83%A0%E5%81%A5%E5%BA%B7%E4%BF%9D%E4%BF%9D%E9%9A%9C%E8%AE%A1%E5%88%92%E8%A1%A8.pdf',
		value: 100,
		page: 2
	}, {
		text: '既往症特别约定',
		url: 'https://f2.youbaolian.top/ysf/2023/%E6%97%A2%E5%BE%80%E7%97%87%E7%89%B9%E5%88%AB%E7%BA%A6%E5%AE%9A.pdf',
		value: 101,
		page: 1
	}, {
		text: '特定药品目录',
		url: 'https://f2.youbaolian.top/ysf/picc/%E7%89%B9%E5%AE%9A%E8%8D%AF%E5%93%81%E7%9B%AE%E5%BD%95.pdf',
		value: 102,
		page: 11
	}, {
		text: '增值服务告知书',
		url: 'https://f2.youbaolian.top/ysf/picc/%E5%A2%9E%E5%80%BC%E6%9C%8D%E5%8A%A1%E5%91%8A%E7%9F%A5%E4%B9%A6.pdf',
		value: 103,
		page: 8
	}
]
export const identityTypes = [ // 证件类型
	{
		text: '身份证',
		value: 11
	}, {
		text: '港澳居民往来内地通行证',
		value: 7
	}, {
		text: '外国人护照',
		value: 2
	}, {
		text: '港澳台居民居住证',
		value: 18
	}, {
		text: '外国人永久居留证',
		value: 19
	}
]
export const relationship = [ // 被保人关系
	{
		text: '本人',
		value: 1
	}, {
		text: '配偶',
		value: 2
	}, {
		text: '父母',
		value: 3
	}, {
		text: '子女',
		value: 4
	}
]
export const InsuranceCompany = [ // 投保保司
	{
		text: '中国人寿',
		value: 9818,
		code: ''
	}, {
		text: '中国平安',
		value: 9803,
		code: 'MP03011318'
	}, {
		text: '中国人保',
		value: 9801,
		code: ''
	},
]
export const gender = [ // 性别
	{
		text: '男性',
		value: 1
	}, {
		text: '女性',
		value: 2
	},
]
export const healthCareRS = [ // 中国人寿财产保险 —— 医疗保险
	{
		text: '基本医保',
		value: 1,
		intro: '北京市基本医疗保险在保状态的参保人员，包括城镇职工医疗保险参保人员、城乡居民医疗保险参保人员'
	}, {
		text: '中央公费医疗',
		value: 2,
		intro: '被保人须为中央公费医疗参保人群请自行核实被保人是否符合参保资格'
	}, {
		text: '新市民',
		value: 3,
		intro: `1.被保人须为拥有北京户籍或持有北京市居住证且已参加异地基本医保的北京市新市民，请自行核实被保人是否符合参保资格。 2.请您在保障生效后办理北京居住证，申请理赔时需提交相关证明，请知悉。`
	}, {
		text: '征地超转等其他医保',
		value: 4,
		intro: '指国家建设征地农民户转为居民户的原农村劳动力中年龄超过转工安置年限的人员及其他符合医疗保险为在保状态的人，包含征地超转、医疗照顾、离休及军休参保人员。请自行核实被保人是否符合参保资格'
	}
]
export const healthCarePA = [ // 中国平安保险 —— 医疗保险
	{
		text: '基本医保',
		value: 1,
		intro: '北京市基本医疗保险在保状态的参保人员，包括城镇职工医疗保险参保人员、城乡居民医疗保险参保人员'
	}, {
		text: '中央公费医疗',
		value: 2,
		intro: '被保人须为中央公费医疗参保人群请自行核实被保人是否符合参保资格'
	}, {
		text: '新市民',
		value: 3,
		intro: `1.被保人须为拥有北京户籍或持有北京市居住证且已参加异地基本医保的北京市新市民，请自行核实被保人是否符合参保资格。 2.请您在保障生效后办理北京居住证，申请理赔时需提交相关证明，请知悉。`
	}, {
		text: '征地超转等其他医保',
		value: 4,
		intro: '指国家建设征地农民户转为居民户的原农村劳动力中年龄超过转工安置年限的人员及其他符合医疗保险为在保状态的人，包含征地超转、医疗照顾、离休及军休参保人员。请自行核实被保人是否符合参保资格'
	}
]
export const healthCareRB = [ // 中国人民保险 —— 医疗保险
	{
		text: '基本医保',
		value: 1,
		intro: '北京市基本医疗保险在保状态的参保人员，包括城镇职工医疗保险参保人员、城乡居民医疗保险参保人员'
	}, {
		text: '中央公费医疗',
		value: 2,
		intro: '被保人须为中央公费医疗参保人群请自行核实被保人是否符合参保资格'
	}, {
		text: '新市民',
		value: 3,
		intro: `1.被保人须为拥有北京户籍或持有北京市居住证且已参加异地基本医保的北京市新市民，请自行核实被保人是否符合参保资格。 2.请您在保障生效后办理北京居住证，申请理赔时需提交相关证明，请知悉。`
	}, {
		text: '征地超转等其他医保',
		value: 4,
		intro: '指国家建设征地农民户转为居民户的原农村劳动力中年龄超过转工安置年限的人员及其他符合医疗保险为在保状态的人，包含征地超转、医疗照顾、离休及军休参保人员。请自行核实被保人是否符合参保资格'
	}
]
export const agreementList = [ // 协议条目
	{
		text: '《保障介绍》',
		value: '01'
	}, {
		text: '《城市定制型团体医条款》',
		value: '02'
	}, {
		text: '《客户告知书》',
		value: '03'
	}, {
		text: '《理赔须知》',
		value: '04'
	}, {
		text: '《参保须知》',
		value: '05'
	}, {
		text: '《产品参保方案》',
		value: '06'
	}, {
		text: '《用户信息授权及个人信息保护政策》',
		value: '07'
	},
]
export const supplier = [ // 保险公司
	{
		text: '中国人保',
		value: 9801
	}, {
		text: '中国平安',
		value: 9803
	}, {
		text: '中国人寿',
		value: 9818
	}
]
export const supplierContact = [ // 保险公司联系电话
	{
		text: '010-95518',
		value: 9801
	}, // 中国人保
	{
		text: '95511',
		value: 9803
	}, // 中国平安
	{
		text: '95519',
		value: 9818
	} // 中国平安
]

export const prem = 195 // 保费
export const insureDto = { // 投保基础信息
	productInsureType: "2", // 产品业务类型
	productCode: "huiminbao2025", // 产品编码
	productId: process.env.NODE_ENV === 'production' ? 593377 : 132382, // 产品id
	productType: "39", // 产品类型 待定
	firstYearPremium: 195, // 首年总保费
	channelSource: 3, // 订单来源
	orderType: 1, // 订单类型
	bankAccount: {
		"payMode": 1
	}, // 付款方式类型
	companyCode: "270", // 投保保司
	// proposer: proposer,
	// insureds: insureds,
	// mainCoverages: mainCoverages,
}
export const proposer = { // 投保人信息模板
	index: "1", // 投保人id
	name: "", // 姓名
	mobile: "", // 联系电话
	identityType: 11, // 证件类型
	identityCode: "", // 证件号
	birthday: "", // 出生日期
	age: null, // 年龄
	gender: 1, // 性别
	nationality: "CHN", // 国籍
}
export const insureds = { // 被保人信息模板
	index: "1", // 投保人id
	relationWithProposer: 1, // 与投保人关系
	sameAsProposer: 1, // 是否同投保人
	name: "", // 姓名
	mobile: "",   // 联系电话
	identityType: 11, // 证件类型
	identityCode: "", // 证件号
	birthday: "", // 出生日期
	age: null, // 年龄
	gender: 1, // 性别
	nationality: "CHN", // 国籍
	socialInsuranceType: "", // 医保类型
	maskData: '', // 加密字符串
}
export const mainCoverages = { // 主险集合
	productCode: "huiminbao2025", // 险种编码
	productId: process.env.NODE_ENV === 'production' ? 593377 : 132382, // 险种id
	productName: "北京普惠健康保(2025)", // 险种名称 待定
	masterProductCode: "huiminbao2025", // 主险编码
	prem: 0, // 保费
	chargeYear: 1, // 缴费期间
	coveragePeriod: 1, // 保障期间
	payPeriodType: "2", // 缴费期间类型
	coverageType: "2", // 保障期间类型
	copies: 1, // 份数
	insuredId: "1", // 被保人id
	exemptFlag: "0", // 豁免险标识
	amount: 3000000, // 保额
}

export const nationality = [ // 国籍
	{
		"value": "AND",
		"text": "安道尔",
		"simpleCode": "AD"
	}, {
		"value": "ARE",
		"text": "阿联酋",
		"simpleCode": "AE"
	}, {
		"value": "AFG",
		"text": "阿富汗",
		"simpleCode": "AF"
	}, {
		"value": "ATG",
		"text": "安提瓜和巴布达",
		"simpleCode": "AG"
	}, {
		"value": "AIA",
		"text": "安圭拉",
		"simpleCode": "AI"
	}, {
		"value": "ALB",
		"text": "阿尔巴尼亚",
		"simpleCode": "AL"
	}, {
		"value": "ARM",
		"text": "亚美尼亚",
		"simpleCode": "AM"
	}, {
		"value": "AGO",
		"text": "安哥拉",
		"simpleCode": "AO"
	}, {
		"value": "ATA",
		"text": "南极洲",
		"simpleCode": "AQ"
	}, {
		"value": "ARG",
		"text": "阿根廷",
		"simpleCode": "AR"
	}, {
		"value": "ASM",
		"text": "美属萨摩亚",
		"simpleCode": "AS"
	}, {
		"value": "AUT",
		"text": "奥地利",
		"simpleCode": "AT"
	}, {
		"value": "AUS",
		"text": "澳大利亚",
		"simpleCode": "AU"
	}, {
		"value": "ABW",
		"text": "阿鲁巴",
		"simpleCode": "AW"
	}, {
		"value": "ALA",
		"text": "奥兰",
		"simpleCode": "AX"
	}, {
		"value": "AZE",
		"text": "阿塞拜疆",
		"simpleCode": "AZ"
	}, {
		"value": "BIH",
		"text": "波斯尼亚和黑塞哥维那",
		"simpleCode": "BA"
	}, {
		"value": "BRB",
		"text": "巴巴多斯",
		"simpleCode": "BB"
	}, {
		"value": "BGD",
		"text": "孟加拉",
		"simpleCode": "BD"
	}, {
		"value": "BEL",
		"text": "比利时",
		"simpleCode": "BE"
	}, {
		"value": "BFA",
		"text": "布基纳法索",
		"simpleCode": "BF"
	}, {
		"value": "BGR",
		"text": "保加利亚",
		"simpleCode": "BG"
	}, {
		"value": "BHR",
		"text": "巴林",
		"simpleCode": "BH"
	}, {
		"value": "BDI",
		"text": "布隆迪",
		"simpleCode": "BI"
	}, {
		"value": "BEN",
		"text": "贝宁",
		"simpleCode": "BJ"
	}, {
		"value": "BLM",
		"text": "圣巴泰勒米",
		"simpleCode": "BL"
	}, {
		"value": "BMU",
		"text": "百慕大",
		"simpleCode": "BM"
	}, {
		"value": "BRN",
		"text": "文莱",
		"simpleCode": "BN"
	}, {
		"value": "BOL",
		"text": "玻利维亚",
		"simpleCode": "BO"
	}, {
		"value": "BES",
		"text": "加勒比荷兰",
		"simpleCode": "BQ"
	}, {
		"value": "BRA",
		"text": "巴西",
		"simpleCode": "BR"
	}, {
		"value": "BHS",
		"text": "巴哈马",
		"simpleCode": "BS"
	}, {
		"value": "BTN",
		"text": "不丹",
		"simpleCode": "BT"
	}, {
		"value": "BVT",
		"text": "布韦岛",
		"simpleCode": "BV"
	}, {
		"value": "BWA",
		"text": "博茨瓦纳",
		"simpleCode": "BW"
	}, {
		"value": "BLR",
		"text": "白俄罗斯",
		"simpleCode": "BY"
	}, {
		"value": "BLZ",
		"text": "伯利兹",
		"simpleCode": "BZ"
	}, {
		"value": "CAN",
		"text": "加拿大",
		"simpleCode": "CA"
	}, {
		"value": "CCK",
		"text": "科科斯（基林）群岛",
		"simpleCode": "CC"
	}, {
		"value": "COD",
		"text": "刚果（金）",
		"simpleCode": "CD"
	}, {
		"value": "CAF",
		"text": "中非",
		"simpleCode": "CF"
	}, {
		"value": "COG",
		"text": "刚果（布）",
		"simpleCode": "CG"
	}, {
		"value": "CHE",
		"text": "瑞士",
		"simpleCode": "CH"
	}, {
		"value": "CIV",
		"text": "科特迪瓦",
		"simpleCode": "CI"
	}, {
		"value": "COK",
		"text": "库克群岛",
		"simpleCode": "CK"
	}, {
		"value": "CHL",
		"text": "智利",
		"simpleCode": "CL"
	}, {
		"value": "CMR",
		"text": "喀麦隆",
		"simpleCode": "CM"
	}, {
		"value": "CHN",
		"text": "中国",
		"simpleCode": "CN"
	}, {
		"value": "COL",
		"text": "哥伦比亚",
		"simpleCode": "CO"
	}, {
		"value": "CRI",
		"text": "哥斯达黎加",
		"simpleCode": "CR"
	}, {
		"value": "CUB",
		"text": "古巴",
		"simpleCode": "CU"
	}, {
		"value": "CPV",
		"text": "佛得角",
		"simpleCode": "CV"
	}, {
		"value": "CUW",
		"text": "库拉索",
		"simpleCode": "CW"
	}, {
		"value": "CXR",
		"text": "圣诞岛",
		"simpleCode": "CX"
	}, {
		"value": "CYP",
		"text": "塞浦路斯",
		"simpleCode": "CY"
	}, {
		"value": "CZE",
		"text": "捷克",
		"simpleCode": "CZ"
	}, {
		"value": "DEU",
		"text": "德国",
		"simpleCode": "DE"
	}, {
		"value": "DJI",
		"text": "吉布提",
		"simpleCode": "DJ"
	}, {
		"value": "DNK",
		"text": "丹麦",
		"simpleCode": "DK"
	}, {
		"value": "DMA",
		"text": "多米尼克",
		"simpleCode": "DM"
	}, {
		"value": "DOM",
		"text": "多米尼加",
		"simpleCode": "DO"
	}, {
		"value": "DZA",
		"text": "阿尔及利亚",
		"simpleCode": "DZ"
	}, {
		"value": "ECU",
		"text": "厄瓜多尔",
		"simpleCode": "EC"
	}, {
		"value": "EST",
		"text": "爱沙尼亚",
		"simpleCode": "EE"
	}, {
		"value": "EGY",
		"text": "埃及",
		"simpleCode": "EG"
	}, {
		"value": "ESH",
		"text": "阿拉伯撒哈拉民主共和国",
		"simpleCode": "EH"
	}, {
		"value": "ERI",
		"text": "厄立特里亚",
		"simpleCode": "ER"
	}, {
		"value": "ESP",
		"text": "西班牙",
		"simpleCode": "ES"
	}, {
		"value": "ETH",
		"text": "埃塞俄比亚",
		"simpleCode": "ET"
	}, {
		"value": "FIN",
		"text": "芬兰",
		"simpleCode": "FI"
	}, {
		"value": "FJI",
		"text": "斐济",
		"simpleCode": "FJ"
	}, {
		"value": "FLK",
		"text": "福克兰群岛",
		"simpleCode": "FK"
	}, {
		"value": "FSM",
		"text": "密克罗尼西亚联邦",
		"simpleCode": "FM"
	}, {
		"value": "FRO",
		"text": "法罗群岛",
		"simpleCode": "FO"
	}, {
		"value": "FRA",
		"text": "法国",
		"simpleCode": "FR"
	}, {
		"value": "GAB",
		"text": "加蓬",
		"simpleCode": "GA"
	}, {
		"value": "GBR",
		"text": "英国",
		"simpleCode": "GB"
	}, {
		"value": "GRD",
		"text": "格林纳达",
		"simpleCode": "GD"
	}, {
		"value": "GEO",
		"text": "格鲁吉亚",
		"simpleCode": "GE"
	}, {
		"value": "GUF",
		"text": "法属圭亚那",
		"simpleCode": "GF"
	}, {
		"value": "GGY",
		"text": "根西",
		"simpleCode": "GG"
	}, {
		"value": "GHA",
		"text": "加纳",
		"simpleCode": "GH"
	}, {
		"value": "GIB",
		"text": "直布罗陀",
		"simpleCode": "GI"
	}, {
		"value": "GRL",
		"text": "格陵兰",
		"simpleCode": "GL"
	}, {
		"value": "GMB",
		"text": "冈比亚",
		"simpleCode": "GM"
	}, {
		"value": "GIN",
		"text": "几内亚",
		"simpleCode": "GN"
	}, {
		"value": "GLP",
		"text": "瓜德罗普",
		"simpleCode": "GP"
	}, {
		"value": "GNQ",
		"text": "赤道几内亚",
		"simpleCode": "GQ"
	}, {
		"value": "GRC",
		"text": "希腊",
		"simpleCode": "GR"
	}, {
		"value": "SGS",
		"text": "南乔治亚和南桑威奇群岛",
		"simpleCode": "GS"
	}, {
		"value": "GTM",
		"text": "危地马拉",
		"simpleCode": "GT"
	}, {
		"value": "GUM",
		"text": "关岛",
		"simpleCode": "GU"
	}, {
		"value": "GNB",
		"text": "几内亚比绍",
		"simpleCode": "GW"
	}, {
		"value": "GUY",
		"text": "圭亚那",
		"simpleCode": "GY"
	}, {
		"value": "HKG",
		"text": "香港",
		"simpleCode": "HK"
	}, {
		"value": "HMD",
		"text": "赫德岛和麦克唐纳群岛",
		"simpleCode": "HM"
	}, {
		"value": "HND",
		"text": "洪都拉斯",
		"simpleCode": "HN"
	}, {
		"value": "HRV",
		"text": "克罗地亚",
		"simpleCode": "HR"
	}, {
		"value": "HTI",
		"text": "海地",
		"simpleCode": "HT"
	}, {
		"value": "HUN",
		"text": "匈牙利",
		"simpleCode": "HU"
	}, {
		"value": "IDN",
		"text": "印尼",
		"simpleCode": "ID"
	}, {
		"value": "IRL",
		"text": "爱尔兰",
		"simpleCode": "IE"
	}, {
		"value": "ISR",
		"text": "以色列",
		"simpleCode": "IL"
	}, {
		"value": "IMN",
		"text": "马恩岛",
		"simpleCode": "IM"
	}, {
		"value": "IND",
		"text": "印度",
		"simpleCode": "IN"
	}, {
		"value": "IOT",
		"text": "英属印度洋领地",
		"simpleCode": "IO"
	}, {
		"value": "IRQ",
		"text": "伊拉克",
		"simpleCode": "IQ"
	}, {
		"value": "IRN",
		"text": "伊朗",
		"simpleCode": "IR"
	}, {
		"value": "ISL",
		"text": "冰岛",
		"simpleCode": "IS"
	}, {
		"value": "ITA",
		"text": "意大利",
		"simpleCode": "IT"
	}, {
		"value": "JEY",
		"text": "泽西",
		"simpleCode": "JE"
	}, {
		"value": "JAM",
		"text": "牙买加",
		"simpleCode": "JM"
	}, {
		"value": "JOR",
		"text": "约旦",
		"simpleCode": "JO"
	}, {
		"value": "JPN",
		"text": "日本",
		"simpleCode": "JP"
	}, {
		"value": "KEN",
		"text": "肯尼亚",
		"simpleCode": "KE"
	}, {
		"value": "KGZ",
		"text": "吉尔吉斯斯坦",
		"simpleCode": "KG"
	}, {
		"value": "KHM",
		"text": "柬埔寨",
		"simpleCode": "KH"
	}, {
		"value": "KIR",
		"text": "基里巴斯",
		"simpleCode": "KI"
	}, {
		"value": "COM",
		"text": "科摩罗",
		"simpleCode": "KM"
	}, {
		"value": "KNA",
		"text": "圣基茨和尼维斯",
		"simpleCode": "KN"
	}, {
		"value": "PRK",
		"text": "朝鲜",
		"simpleCode": "KP"
	}, {
		"value": "KOR",
		"text": "韩国",
		"simpleCode": "KR"
	}, {
		"value": "KWT",
		"text": "科威特",
		"simpleCode": "KW"
	}, {
		"value": "CYM",
		"text": "开曼群岛",
		"simpleCode": "KY"
	}, {
		"value": "KAZ",
		"text": "哈萨克斯坦",
		"simpleCode": "KZ"
	}, {
		"value": "LAO",
		"text": "老挝",
		"simpleCode": "LA"
	}, {
		"value": "LBN",
		"text": "黎巴嫩",
		"simpleCode": "LB"
	}, {
		"value": "LCA",
		"text": "圣卢西亚",
		"simpleCode": "LC"
	}, {
		"value": "LIE",
		"text": "列支敦士登",
		"simpleCode": "LI"
	}, {
		"value": "LKA",
		"text": "斯里兰卡",
		"simpleCode": "LK"
	}, {
		"value": "LBR",
		"text": "利比里亚",
		"simpleCode": "LR"
	}, {
		"value": "LSO",
		"text": "莱索托",
		"simpleCode": "LS"
	}, {
		"value": "LTU",
		"text": "立陶宛",
		"simpleCode": "LT"
	}, {
		"value": "LUX",
		"text": "卢森堡",
		"simpleCode": "LU"
	}, {
		"value": "LVA",
		"text": "拉脱维亚",
		"simpleCode": "LV"
	}, {
		"value": "LBY",
		"text": "利比亚",
		"simpleCode": "LY"
	}, {
		"value": "MAR",
		"text": "摩洛哥",
		"simpleCode": "MA"
	}, {
		"value": "MCO",
		"text": "摩纳哥",
		"simpleCode": "MC"
	}, {
		"value": "MDA",
		"text": "摩尔多瓦",
		"simpleCode": "MD"
	}, {
		"value": "MNE",
		"text": "黑山",
		"simpleCode": "ME"
	}, {
		"value": "MAF",
		"text": "法属圣马丁",
		"simpleCode": "MF"
	}, {
		"value": "MDG",
		"text": "马达加斯加",
		"simpleCode": "MG"
	}, {
		"value": "MHL",
		"text": "马绍尔群岛",
		"simpleCode": "MH"
	}, {
		"value": "MKD",
		"text": "马其顿",
		"simpleCode": "MK"
	}, {
		"value": "MLI",
		"text": "马里",
		"simpleCode": "ML"
	}, {
		"value": "MMR",
		"text": "缅甸",
		"simpleCode": "MM"
	}, {
		"value": "MNG",
		"text": "蒙古",
		"simpleCode": "MN"
	}, {
		"value": "MAC",
		"text": "澳门",
		"simpleCode": "MO"
	}, {
		"value": "MNP",
		"text": "北马里亚纳群岛",
		"simpleCode": "MP"
	}, {
		"value": "MTQ",
		"text": "马提尼克",
		"simpleCode": "MQ"
	}, {
		"value": "MRT",
		"text": "毛里塔尼亚",
		"simpleCode": "MR"
	}, {
		"value": "MSR",
		"text": "蒙特塞拉特",
		"simpleCode": "MS"
	}, {
		"value": "MLT",
		"text": "马耳他",
		"simpleCode": "MT"
	}, {
		"value": "MUS",
		"text": "毛里求斯",
		"simpleCode": "MU"
	}, {
		"value": "MDV",
		"text": "马尔代夫",
		"simpleCode": "MV"
	}, {
		"value": "MWI",
		"text": "马拉维",
		"simpleCode": "MW"
	}, {
		"value": "MEX",
		"text": "墨西哥",
		"simpleCode": "MX"
	}, {
		"value": "MYS",
		"text": "马来西亚",
		"simpleCode": "MY"
	}, {
		"value": "MOZ",
		"text": "莫桑比克",
		"simpleCode": "MZ"
	}, {
		"value": "NAM",
		"text": "纳米比亚",
		"simpleCode": "NA"
	}, {
		"value": "NCL",
		"text": "新喀里多尼亚",
		"simpleCode": "NC"
	}, {
		"value": "NER",
		"text": "尼日尔",
		"simpleCode": "NE"
	}, {
		"value": "NFK",
		"text": "诺福克岛",
		"simpleCode": "NF"
	}, {
		"value": "NGA",
		"text": "尼日利亚",
		"simpleCode": "NG"
	}, {
		"value": "NIC",
		"text": "尼加拉瓜",
		"simpleCode": "NI"
	}, {
		"value": "NLD",
		"text": "荷兰",
		"simpleCode": "NL"
	}, {
		"value": "NOR",
		"text": "挪威",
		"simpleCode": "NO"
	}, {
		"value": "NPL",
		"text": "尼泊尔",
		"simpleCode": "NP"
	}, {
		"value": "NRU",
		"text": "瑙鲁",
		"simpleCode": "NR"
	}, {
		"value": "NIU",
		"text": "纽埃",
		"simpleCode": "NU"
	}, {
		"value": "NZL",
		"text": "新西兰",
		"simpleCode": "NZ"
	}, {
		"value": "OMN",
		"text": "阿曼",
		"simpleCode": "OM"
	}, {
		"value": "PAN",
		"text": "巴拿马",
		"simpleCode": "PA"
	}, {
		"value": "PER",
		"text": "秘鲁",
		"simpleCode": "PE"
	}, {
		"value": "PYF",
		"text": "法属波利尼西亚",
		"simpleCode": "PF"
	}, {
		"value": "PNG",
		"text": "巴布亚新几内亚",
		"simpleCode": "PG"
	}, {
		"value": "PHL",
		"text": "菲律宾",
		"simpleCode": "PH"
	}, {
		"value": "PAK",
		"text": "巴基斯坦",
		"simpleCode": "PK"
	}, {
		"value": "POL",
		"text": "波兰",
		"simpleCode": "PL"
	}, {
		"value": "SPM",
		"text": "圣皮埃尔和密克隆",
		"simpleCode": "PM"
	}, {
		"value": "PCN",
		"text": "皮特凯恩群岛",
		"simpleCode": "PN"
	}, {
		"value": "PRI",
		"text": "波多黎各",
		"simpleCode": "PR"
	}, {
		"value": "PSE",
		"text": "巴勒斯坦",
		"simpleCode": "PS"
	}, {
		"value": "PRT",
		"text": "葡萄牙",
		"simpleCode": "PT"
	}, {
		"value": "PLW",
		"text": "帕劳",
		"simpleCode": "PW"
	}, {
		"value": "PRY",
		"text": "巴拉圭",
		"simpleCode": "PY"
	}, {
		"value": "QAT",
		"text": "卡塔尔",
		"simpleCode": "QA"
	}, {
		"value": "REU",
		"text": "留尼汪",
		"simpleCode": "RE"
	}, {
		"value": "ROU",
		"text": "罗马尼亚",
		"simpleCode": "RO"
	}, {
		"value": "SRB",
		"text": "塞尔维亚",
		"simpleCode": "RS"
	}, {
		"value": "RUS",
		"text": "俄罗斯",
		"simpleCode": "RU"
	}, {
		"value": "RWA",
		"text": "卢旺达",
		"simpleCode": "RW"
	}, {
		"value": "SAU",
		"text": "沙特阿拉伯",
		"simpleCode": "SA"
	}, {
		"value": "SLB",
		"text": "所罗门群岛",
		"simpleCode": "SB"
	}, {
		"value": "SYC",
		"text": "塞舌尔",
		"simpleCode": "SC"
	}, {
		"value": "SDN",
		"text": "苏丹",
		"simpleCode": "SD"
	}, {
		"value": "SWE",
		"text": "瑞典",
		"simpleCode": "SE"
	}, {
		"value": "SGP",
		"text": "新加坡",
		"simpleCode": "SG"
	}, {
		"value": "SHN",
		"text": "圣赫勒拿",
		"simpleCode": "SH"
	}, {
		"value": "SVN",
		"text": "斯洛文尼亚",
		"simpleCode": "SI"
	}, {
		"value": "SJM",
		"text": "挪威 斯瓦尔巴群岛和扬马延岛",
		"simpleCode": "SJ"
	}, {
		"value": "SVK",
		"text": "斯洛伐克",
		"simpleCode": "SK"
	}, {
		"value": "SLE",
		"text": "塞拉利昂",
		"simpleCode": "SL"
	}, {
		"value": "SMR",
		"text": "圣马力诺",
		"simpleCode": "SM"
	}, {
		"value": "SEN",
		"text": "塞内加尔",
		"simpleCode": "SN"
	}, {
		"value": "SOM",
		"text": "索马里",
		"simpleCode": "SO"
	}, {
		"value": "SUR",
		"text": "苏里南",
		"simpleCode": "SR"
	}, {
		"value": "SSD",
		"text": "南苏丹",
		"simpleCode": "SS"
	}, {
		"value": "STP",
		"text": "圣多美和普林西比",
		"simpleCode": "ST"
	}, {
		"value": "SLV",
		"text": "萨尔瓦多",
		"simpleCode": "SV"
	}, {
		"value": "SXM",
		"text": "荷属圣马丁",
		"simpleCode": "SX"
	}, {
		"value": "SYR",
		"text": "叙利亚",
		"simpleCode": "SY"
	}, {
		"value": "SWZ",
		"text": "斯威士兰",
		"simpleCode": "SZ"
	}, {
		"value": "TCA",
		"text": "特克斯和凯科斯群岛",
		"simpleCode": "TC"
	}, {
		"value": "TCD",
		"text": "乍得",
		"simpleCode": "TD"
	}, {
		"value": "ATF",
		"text": "法属南部领地",
		"simpleCode": "TF"
	}, {
		"value": "TGO",
		"text": "多哥",
		"simpleCode": "TG"
	}, {
		"value": "THA",
		"text": "泰国",
		"simpleCode": "TH"
	}, {
		"value": "TJK",
		"text": "塔吉克斯坦",
		"simpleCode": "TJ"
	}, {
		"value": "TKL",
		"text": "托克劳",
		"simpleCode": "TK"
	}, {
		"value": "TLS",
		"text": "东帝汶",
		"simpleCode": "TL"
	}, {
		"value": "TKM",
		"text": "土库曼斯坦",
		"simpleCode": "TM"
	}, {
		"value": "TUN",
		"text": "突尼斯",
		"simpleCode": "TN"
	}, {
		"value": "TON",
		"text": "汤加",
		"simpleCode": "TO"
	}, {
		"value": "TUR",
		"text": "土耳其",
		"simpleCode": "TR"
	}, {
		"value": "TTO",
		"text": "特立尼达和多巴哥",
		"simpleCode": "TT"
	}, {
		"value": "TUV",
		"text": "图瓦卢",
		"simpleCode": "TV"
	}, {
		"value": "TWN",
		"text": "台湾 台湾",
		"simpleCode": "TW"
	}, {
		"value": "TZA",
		"text": "坦桑尼亚",
		"simpleCode": "TZ"
	}, {
		"value": "UKR",
		"text": "乌克兰",
		"simpleCode": "UA"
	}, {
		"value": "UGA",
		"text": "乌干达",
		"simpleCode": "UG"
	}, {
		"value": "UMI",
		"text": "美国本土外小岛屿",
		"simpleCode": "UM"
	}, {
		"value": "USA",
		"text": "美国",
		"simpleCode": "US"
	}, {
		"value": "URY",
		"text": "乌拉圭",
		"simpleCode": "UY"
	}, {
		"value": "UZB",
		"text": "乌兹别克斯坦",
		"simpleCode": "UZ"
	}, {
		"value": "VAT",
		"text": "梵蒂冈",
		"simpleCode": "VA"
	}, {
		"value": "VCT",
		"text": "圣文森特和格林纳丁斯",
		"simpleCode": "VC"
	}, {
		"value": "VEN",
		"text": "委内瑞拉",
		"simpleCode": "VE"
	}, {
		"value": "VGB",
		"text": "英属维尔京群岛",
		"simpleCode": "VG"
	}, {
		"value": "VIR",
		"text": "美属维尔京群岛",
		"simpleCode": "VI"
	}, {
		"value": "VNM",
		"text": "越南",
		"simpleCode": "VN"
	}, {
		"value": "VUT",
		"text": "瓦努阿图",
		"simpleCode": "VU"
	}, {
		"value": "WLF",
		"text": "瓦利斯和富图纳",
		"simpleCode": "WF"
	}, {
		"value": "WSM",
		"text": "萨摩亚",
		"simpleCode": "WS"
	}, {
		"value": "YEM",
		"text": "也门",
		"simpleCode": "YE"
	}, {
		"value": "MYT",
		"text": "马约特",
		"simpleCode": "YT"
	}, {
		"value": "ZAF",
		"text": "南非",
		"simpleCode": "ZA"
	}, {
		"value": "ZMB",
		"text": "赞比亚",
		"simpleCode": "ZM"
	}, {
		"value": "ZWE",
		"text": "津巴布韦",
		"simpleCode": "ZW"
	}
]